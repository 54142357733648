import { useEffect, useRef } from "react";

import { pushDataLayer } from "~/lib/analytics/datalayer";
import { useAuth } from "~features/auth";
import { cx } from "~utils";

import { getPaywallConfig } from "./constants";
import { useFocusTrapping } from "./useFocusTrapping";

import styles from "./paywallModal.module.css";

export default function PaywallModal({ meterCount }) {
  const tp = window.tp || [];
  const { login, user } = useAuth();
  const trialModalOverlayRef = useRef(null);

  // Keep keyboard focus within the paywall modal
  useFocusTrapping(trialModalOverlayRef);

  /* Set the string for getPaywallConfig() for the default paywall language you want to display
   * "default": Normal paywall offer
   * "thirtyDayTrial": 30-day free trial that converts to an annual digital sub.
   * "sixtyDayOneDollar": 60-day for $1 that converts to an annual digital sub.
   * "ninetyDayOneDollar": 90-day for $1 that converts to an annual digital sub.
   * "cyberMonday": Cyber Monday offer
   * "sixmonths30": Six months for $30 that converts to an annual unlimited sub.
   */
  let paywallExperience = "ninetyDayOneDollar";

  /* Debug tool: Add debug=thirtyDayTrial and debug=paywall to the URL as query parameters to see the 30-day free trial modal */
  if (window.location.search.match(/debug=thirtydaytrial/)) {
    paywallExperience = "thirtyDayTrial";
  }

  /* Debug tool: Add debug=ninetyDayOneDollar and debug=paywall to the URL as query parameters to see the 60-day for $1 modal */
  if (window.location.search.match(/debug=sixtydayonedollar/)) {
    paywallExperience = "sixtyDayOneDollar";
  }

  /* Debug tool: Add debug=ninetyDayOneDollar and debug=paywall to the URL as query parameters to see the 90-day for $1 modal */
  if (window.location.search.match(/debug=ninetydayonedollar/)) {
    paywallExperience = "ninetyDayOneDollar";
  }

  /* Debug tool: Add debug=cyberMonday and debug=paywall to the URL as query parameters to see the Cyber Monday modal */
  if (window.location.search.match(/debug=cybermonday/)) {
    paywallExperience = "cyberMonday";
  }

  /* Debug tool: Add debug=cyberMonday and debug=paywall to the URL as query parameters to see the Cyber Monday modal */
  if (window.location.search.match(/debug=sixmonths30/)) {
    paywallExperience = "sixmonths30";
  }

  const paywallConfig = getPaywallConfig(paywallExperience);

  /* Datalayer logging when the modal opens up */
  useEffect(() => {
    pushDataLayer({
      event: "modalOpened",
      modalName: paywallConfig?.datalayer?.modalName,
      user: {
        meteredPaywallArticleNum: meterCount,
      },
    });
  }, []);

  /* Call Piano checkout flow */
  function handleTrialSubmit() {
    tp.offer?.startCheckout({
      offerId: paywallConfig?.offerId,
      termId: paywallConfig?.termId,
      promoCode: paywallConfig?.promoCode || undefined,
    });
  }

  return (
    <>
      <div id="trialModalOverlay" className={styles.trialModalOverlay} ref={trialModalOverlayRef}>
        <section className={styles.trialModalWrap}>
          <div className={cx(styles.trialModalHdrWrap, user ? styles.isCentered : "")}>
            {!user && (
              <div>
                <p className={styles.trialModalHdrTxt}>
                  Already a subscriber?{" "}
                  <a
                    href="/account/"
                    id="trialModalSignInLnk"
                    className={styles.trialModalSignInLnk}
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                  >
                    Sign in
                  </a>
                </p>
              </div>
            )}
            <div>
              <p className={styles.trialModalHdrTxt}>
                Only get us in print?{" "}
                <a
                  href="/account/link/"
                  id="trialModalConnectLnk"
                  className={styles.trialModalConnectLnk}
                >
                  Connect your account
                </a>
              </p>
            </div>
          </div>
          <section className={styles.trialModalFormWrap}>
            <p className={styles.trialModalMainTxt}>{paywallConfig?.mainText}</p>
            <p
              className={styles.trialModalSubTxt}
              dangerouslySetInnerHTML={{ __html: paywallConfig?.subText }}
            ></p>
            <ul className={styles.trialBenefitsList}>
              {paywallConfig?.benefits.map((text) => {
                return (
                  <li className={styles.trialBenefitItem} key={text}>
                    {text}
                  </li>
                );
              })}
            </ul>
            <form id="trialForm" className={styles.trialForm}>
              <button
                type="button"
                id="trialBtn"
                className={styles.trialBtn}
                onClick={handleTrialSubmit}
              >
                <span className={styles.trialBtnTxt}>{paywallConfig?.buttonCTA}</span>
              </button>
            </form>
            <p
              className={styles.trialModalFinePrint}
              dangerouslySetInnerHTML={{ __html: paywallConfig?.finePrint }}
            ></p>
          </section>
          <section className={styles.trialModalOffersWrap}>
            <span className={styles.trialModalOffersFinePrint}>
              Interested in a print subscription?
            </span>
            <a href="/getsciam/" target="_blank" className={styles.trialModalOffersLnk}>
              {paywallConfig?.finePrintLink || "See all subscription offers"}
            </a>
          </section>
        </section>
      </div>
    </>
  );
}
